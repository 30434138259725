import '../css/privacy.css';

export default function Privacy() {
    return (
        <main className="dark:bg-zinc-800">
            <section className="section">
                <div className="section-padding">
                    <h1 className="section-title dark:text-white">Privacy Policy Statement</h1>
                    <hr className="section-title__underline dark:border-white" />
                    <p className="dark:text-white">Symmetry Advisory Solutions respects the privacy rights of our clients and recognizes the importance of protecting the information collected from you. We have adopted a corporate-wide Privacy Policy that guides how we collect, store, and use the information we provide. Please note that this policy applies only to sites maintained by Symmetry, and not to websites maintained by other companies or organizations to which we link. If you have questions or concerns regarding this statement, you should contact Symmetry at <a href="mailto:solutions@symsolutionscpa.com" className="underline hover:text-[#1987c0]">solutions@symsolutionscpa.com</a>.</p>
                    
                    <h2 className="col-title dark:text-white">Opt-Out Options and Mobile Information</h2>
                    <hr className="col-title__underline sm:mx-auto" />
                    <p className="mb-[0.3rem] dark:text-white">You have the right to opt out of receiving marketing communications from us at any time by:</p>
                    <ul className="list dark:text-white">
                        <li className="dark:text-white"><strong>SMS:</strong> Replying "STOP" to any SMS message your receive from us.</li>
                        <li className="dark:text-white"><strong>Email:</strong> Clicking the "unsubscribe" link in any email you receive from us.</li>
                        <li className="dark:text-white"><strong>Account Settings:</strong> Updating your preferences in your account settings on our website.</li>
                    </ul>
                    <p className="mb-[0.3rem] dark:text-white">More on mobile information usage, collection, and privacy</p>
                    <ul className="list dark:text-white">
                        <li className="dark:text-white">Our SMS program provides updates, notifications, and messages related to our services. By subscribing to our SMS program, you consent to receive SMS messages at the phone number provided. Message frequency may vary based on your interaction with our service.</li>
                        <li className="dark:text-white">We do not share your mobile information with third parties or affiliates for <strong><i>any</i></strong> purpose. Additionally, all the categories of personally identifiable information mentioned below exclude text messaging originator or opt-in data and conset. This information will not be shared with any third parties under any circumstances. However, we may share your data with trusted service providers who assist us in operating our SMS program, provided they agree to keep your data confidential and use it solely for the purpose of providing services on our behalf.</li>
                        <li className="dark:text-white">You have the right to access, correct, verify, or remove your personal information at any time. To manage your information, please contact us at <a href="tel:9377017961">(937) 701-7961</a>, ext. 100.</li>
                        <li className="dark:text-white"><strong>Opt-Out Option:</strong> If you wish to opt-out of receiving SMS messages, you may do so at any time by replying "STOP" to any of our messages or by contacting us directly at <a href="tel:9377017961">(937) 701-7961</a>, ext. 100. Opting out will not affect your other interactions with our services.</li>
                    </ul>
                    <p className="mb-0 dark:text-white"><strong>Paperless Document Retention-Policy Statement</strong></p>
                    <p className="dark:text-white">Symmetry Advisory Solutions use a paperless office environment in order to remain on the cutting edge of the industry and to keep pace with current trends in the courts and other venues. As a result, Symmetry requests the production of documents in electronic format whenever and wherever possible. By reviewing electronic copies of the documents, significant costs can and will be saved by all parties involved in costly litigation, as handling, copying, postage, and storage costs continue to mount.</p>
                    <ul className="list dark:text-white">
                        <li className="dark:text-white">In practical terms, substantially all documents that are provided to Symmetry are copies of copies or copies of original documents, and it is exceptionally rare that original documents are provided to us. During any engagement, we will retain the hard copy of any records or copies provided to us until our involvement in the matter is clearly concluded.  At the end of our engagement, however, Symmetry will return (or destroy, if directed to do so) such copies of documents as directed by our client. These documents would have previously been scanned and archived into our electronic files. If the documents have not been scanned and archived by us, for whatever reason, we will return them to the party to whom we are directed. On the rare occasion when actual original documents are provided, we will immediately scan those originals and return them promptly.</li>
                        <li className="dark:text-white">Symmetry will retain in electronic format all scanned documents, including workpapers, reports, and related correspondence, etc. utilized in performing our services for a period of 5 years. The 5 years will commence the January 1st of the year immediately following the date of the engagement letter and will run to the December 31st 5 years later, at which time they will be purged. A final copy of our work product/report (if one is requested during the engagement and exists) will be retained indefinitely. All the electronically archived documents will be available for retrieval and reproduction at your request during the 5-year period, and we will bill only our costs for this service.</li>
                    </ul>
                    
                    <h2 className="col-title dark:text-white">Information Collection & Use</h2>
                    <hr className="col-title__underline sm:mx-auto" />
                    <p className="dark:text-white">Our primary goal in collecting personally identifiable information is to provide you with the products and services we make available, including, but not limited to our services, to communicate with you, and to manage your account.</p>
                    <p className="dark:text-white"><strong>Information Collected:</strong> In most instances, Symmetry will be unable to provide services without obtaining certain personally identifiable information. Personally identifiable information that we may collect in such instances may include your full name, email address, home address, telephone number, occupation, SSN, bank account and routing numbers, personal financial information and other information that you decide to provide us with.</p>
                    <p className="dark:text-white"><strong>Safeguarding of Personally Identifiable Information:</strong> Symmetry uses many electronic means to obtain, analyze and store information related to our clients' needs.  We are committed to using password protected and encrypted solutions that include the same level of security offered by banks and financial institutions.  Access to information is strictly limited to only those within our company who have a need to work with and know the information.  Access levels are managed by password protection at the file and folder levels and may include further encryption at the file level, as appropriate.  We minimize the submission of data and documentation via email, preferring to share documentation that may contain personally identifiable information via secure online portals.</p>
                    <p className="dark:text-white"><strong>Use of Contact Information:</strong> We will NOT use your personal information to provide marketing materials to you unless we have obtained your permission. We respect your privacy and will not use your information, nor will we ever provide it to any third party for any purposes.</p>
                    <p className="dark:text-white"><strong>Law Enforcement:</strong> We cooperate with government and law enforcement officials or private parties to enforce and comply with the law. We may disclose any information about you to government or law enforcement officials or private parties as we, in our sole discretion, believe necessary or appropriate to respond to claims, legal process (including subpoenas), to protect the property and rights of Company or a third party, the safety of the public or any person, to prevent or stop any illegal, unethical, or legally actionable activity, or to comply with the law.</p>
                </div>
            </section>
        </main>
    )
}